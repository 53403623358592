module services {
    export module tariff {
        export class customsProcedureMatrixService implements interfaces.tariff.ICustomsProcedureMatrixService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            get(countryId: number, procedureCategoryId: number, customsProcedureId?: number, prevCustomsProcedureId?: number): ng.resource.IResourceClass<interfaces.tariff.ICustomsProcedureMatrix> {

                return this.$resource<interfaces.tariff.ICustomsProcedureMatrix>(this.ENV.DSP_URL + "CustomsProcedureMatrix/Get", {
                    countryId: countryId,
                    procedureCategoryId: procedureCategoryId,
                    customsProcedureId: customsProcedureId,
                    prevCustomsProcedureId: prevCustomsProcedureId
                });
            }

            getCustomsProcedureDropdownList(countryId: number, procedureCategoryId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomsProcedureMatrix/GetCustomsProcedureDropdownList", {
                    countryId: countryId,
                    procedureCategoryId: procedureCategoryId
                });
            }

            getPrevCustomsProcedureDropdownList(countryId: number, procedureCategoryId?: number, customsProcedureId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomsProcedureMatrix/GetPrevCustomsProcedureDropdownList", {
                    countryId: countryId,
                    procedureCategoryId: procedureCategoryId,
                    customsProcedureId: customsProcedureId
                });
            }

            getProcedureCategoryDropdownList(countryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomsProcedureMatrix/GetProcedureCategoryDropdownList", {
                    countryId: countryId
                });
            }
        }
    }
    angular.module("app").service("customsProcedureMatrixService", services.tariff.customsProcedureMatrixService);
}